<!-- 相关资讯 -->
<template>
  <div class="message">
    <Breadcrumb style="margin-top: 20px;margin-left: 130px;margin-bottom: 20px;color: rgb(110, 114, 117);">
      <BreadcrumbItem to="/">首页</BreadcrumbItem>
      <BreadcrumbItem to="/message">相关资讯</BreadcrumbItem>
    </Breadcrumb>
    <ul>
      <li style="border: none;" v-for="item in Data">
        <div class='nextkuang' style="margin-top: 20px;">
          <div class='xgleft1'>{{ item.time }}</div>
          <router-link to="/page1" class='text1'>{{ item.title }}</router-link>
          <div class="xgleft3"></div>
          <div class="xgleft4">{{ item.redtext }}</div>
          <router-link to="/page1" class="text2">{{ item.content }}</router-link>
          <div style="cursor: pointer;margin-left: 40px;" @click="go(item.path)">
            <div class="text3">查看详情<img src="../../../assets/images/you.png"></div>
          </div>
        </div>
        <!-- <div style="margin-bottom: 30px;"></div> -->
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Data: [
        {
          name: 1,
          time: "2023.07.11",
          title: "黄埔区妇联、人社局联合调研朝九晚四巾帼创客中心",
          redtext: "各界关怀",
          content: "7月10日上午，黄埔区妇联主席王晓虹、人社局副局长黄智祥、区妇联副主席宋赫男、区就业管理中心主任綦宗彬、就业处王晨等领导一行，就省人社厅、省妇联发布的《关于推行“妈妈岗”模式，促进妇女就业的实施意见》的征求意见稿和朝九晚四巾帼创客中心近年的运营情况......",
          path: 'page1',
        },
        {
          name: 2,
          time: "2023.06.01",
          title: "巾帼妈妈灵活创业外展就业招聘服务活动",
          redtext: "就业创业",
          content: "近日，朝九晚四为了让巾帼妈妈了解招聘，提升巾帼妈妈的招聘能力，中心链接招聘会资源让妈妈开展外展就业招聘服务，希望妈妈能从实践中提升技能，拓展个人招聘资源，促进就业岗位对接实现灵活增收......",
          path: 'page2',
        },
        {
          name: 3,
          time: "2023.05.17",
          title: "【区创投项目】“促进就业，宝妈创业”线下宣传招募活动",
          redtext: "就业创业",
          content: "让更多妈妈了解朝九晚四“促进就业，宝妈创业”巾帼妈妈灵活创业互助项目，让有意愿灵活创业增收的妈妈有渠道实现自己的想法，助力妈妈步入社会，提升自我。5月16日，朝九晚四巾帼创客中心工作人员在新东党建文化广场开展宣传活动......",
          path: 'page3',
        },
        {
          name: 4,
          time: "2023.02.07",
          title: "区妇联王主席视察朝九晚四，推进妇女灵活就业工作",
          redtext: "各界关怀",
          content: "2023年2月7日上午，黄埔区妇联主席王晓红带队莅临朝九晚四创客中心，指导朝九晚四妇女灵活就业工作。中心理事长王盛虎先生对朝九晚四2023年宏“兔”计划做了详细介绍。王晓红主席充分肯定朝九晚四在妇女灵活就业方面的成果......",
          path: 'page4',
        },
        {
          name: 5,
          time: "2022.09.06",
          title: "黄埔区妇女就业创业技能培训顺利开展",
          redtext: "各界关怀",
          content: "为了提升黄埔区妇女就业创业能力，推动妇女融入社会，实现灵活就业创业，激发我区广大妇女群众积极投身经济社会高质量发展，9月6日上午，在区妇联主办和支持下黄埔区朝九晚四巾帼创客服务中心在萝岗街党群服务中心开展妇女就业创业技能培训，来自萝岗街、联和街、长岭街、云埔街的40多名妇女参加本次培训班......",
          path: 'page5',
        },
        {
          name: 6,
          time: "2022.08.23",
          title: "【公益创投 暖席广州】朝九晚四“三步学会电商直播”主题培训",
          redtext: "就业创业",
          content: "为了充分发挥妇女能顶半边天的作用，结合灵活就业的大趋势，盘活乡村妇女劳动力， 2022年8月23日上午，第九届广州市社会组织公益创投活动“乡村振兴，社企共建——困境妇女直播技能提升帮扶计划”......",
          path: 'page6',
        },
        {
          name: 7,
          time: "2022.05.16",
          title: "朝九晚四灵活用工进园区公益活动--走进莱迪创新科技园",
          redtext: "各界关怀",
          content: "上周，朝九晚四巾帼创客中心为继续推动育儿妈妈灵活就业，充分发挥女性能顶“半边天”的重要作用，走进广州莱迪创新科技园区，与其负责人共同探讨园区内的朝九晚四灵活就业岗位开发主题......",
          path: 'page7',
        },
        {
          name: 8,
          time: "2022.05.10",
          style: "height:60px",
          title: "广州火炬高新技术创业服务中心领导参观朝九晚四巾帼创客中心",
          redtext: "各界关怀",
          content: "5月9日下午，广州火炬高新技术创业服务中心企业服务部杨乐怡、广州开发区科技企业孵化器协会秘书长王天宜、工作人员严雪婧前来朝九晚四巾帼创客中心走访参观。双方就如何更好地以服务中小型企业会员的机遇给育儿妈妈创造更多灵活就业爱心岗位来推动社会事业发展的主题进行深入的交流探讨......",
          path: 'page8',
        },

      ]
    }
  },
  methods: {
    go(path) {
      this.$router.push({ name: path })
    }
  },
  created() {

  }
}
</script>

<style lang="less" scoped>
@import "./message.less";
@import "./page.less";
</style>
